@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Monomaniac+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Megrim&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap");

* {
  scroll-behavior: smooth;
}

.introcomp {
  width: 100%;
  height: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 50px;
  text-align: center;
}

.pot {
  font-size: 99px;
  z-index: -1;
}

.montserrat {
  font-family: "Montserrat", sans-serif;
}

.ptserif {
  font-family: "PT Serif", serif;
}

.megrim {
  font-family: "Megrim", sans-serif;
}

.poppins {
  font-family: "Poppins", sans-serif;
}

@keyframes floating {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 23px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.floating-effect {
  animation: floating 2s ease-in-out infinite;
}

.floating-effect2 {
  animation: floating 3s ease-in-out infinite;
}

@media (max-width: 767px) {
  .introcomp {
    height: 100vh;
    background-image: linear-gradient(
      rgb(3, 105, 161),
      rgb(14, 165, 233),
      rgb(255, 255, 255)
    );
  }

  svg {
    width: 100%;
    height: 100%;
  }

  .text1 {
    font-size: 80px;
  }

  .mountBg,
  .mountMg,
  .mountFg,
  .cloud1,
  .cloud2,
  .cloud3,
  .cloudmask {
    width: 150%;
    height: 290%;
  }

  .cloud1 {
    width: 180%;
    height: 270%;
  }

  .sky {
    display: none;
  }

  .arrow {
    transform: translateY(200px);
  }
  .arrowBtn {
    transform: translateY(200px);
  }
}

@-webkit-keyframes focus-in-expand {
  0% {
    letter-spacing: -0.5em;
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes focus-in-expand {
  0% {
    letter-spacing: -0.5em;
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

.focus-in-expand {
  -webkit-animation: focus-in-expand 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: focus-in-expand 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.loading-animation {
  transition: opacity 1s ease-out; /* Adjust the duration as needed (in seconds) */
  opacity: 1;
}

.loading-animation.fade-out {
  opacity: 0;
}

.wrapper {
  width: 200px;
  height: 60px;
  position: relative;
  z-index: 1;
}

.circle {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  background-color: #fff;
  left: 15%;
  transform-origin: 50%;
  animation: circle7124 0.5s alternate infinite ease;
}

@keyframes circle7124 {
  0% {
    top: 60px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }

  40% {
    height: 20px;
    border-radius: 50%;
    transform: scaleX(1);
  }

  100% {
    top: 0%;
  }
}

.circle:nth-child(2) {
  left: 45%;
  animation-delay: 0.2s;
}

.circle:nth-child(3) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}

.shadow {
  width: 20px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.9);
  position: absolute;
  top: 62px;
  transform-origin: 50%;
  z-index: -1;
  left: 15%;
  filter: blur(1px);
  animation: shadow046 0.5s alternate infinite ease;
}

@keyframes shadow046 {
  0% {
    transform: scaleX(1.5);
  }

  40% {
    transform: scaleX(1);
    opacity: 0.7;
  }

  100% {
    transform: scaleX(0.2);
    opacity: 0.4;
  }
}

.shadow:nth-child(4) {
  left: 45%;
  animation-delay: 0.2s;
}

.shadow:nth-child(5) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}

@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

.text-focus-in {
  -webkit-animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

.animate-scroll {
  display: flex;
  animation: scroll 40s linear infinite;
}
